.CardSolutionContainer::before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 100%;
  height: 0;
  transition: 0.5s;
  border-radius: 8px;
  background-color: white;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  backdrop-filter: blur(10px);
}

.CardSolutionContainer:hover::before {
  height: 100%;
}

.CardSolutionContainer:hover .SubContent {
  opacity: 1;
}

/* .CardSolutionContainer:hover .Image-CardSolutionContainer {
  filter: brightness(50%);
} */

.Button3dView {
  position: relative;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 6px;
  display: inline-block;
  transition: all 0.2s;
  border: none;
  font-family: inherit;
  font-weight: 500;
  color: black;
  background-color: white;
}

.Button3dView:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.Button3dView:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.Button3dView::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.Button3dView::after {
  background-color: #fff;
}

.Button3dView:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}
