#video {
  position: absolute;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  filter: brightness(50%);
}

#video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.animation {
  height: 50px;
  overflow: hidden;
  font-size: 22px;
  color: white;
}

.animation > div > div {
  height: 2.81rem;
  margin-bottom: 2.81rem;
  display: inline-block;
}

.animation div:first-child {
  animation: text-animation 10s infinite;
}

@keyframes text-animation {
  0% {
    margin-top: 0;
  }
  12.5% {
    margin-top: -5.62rem;
  }
  25% {
    margin-top: -5.62rem;
  }
  37.5% {
    margin-top: -11.24rem;
  }
  40% {
    margin-top: -11.24rem;
  }
  52.5% {
    margin-top: -16.87rem;
  }
  75% {
    margin-top: -16.87rem;
  }
  87.5% {
    margin-top: -22.49rem;
  }
  100% {
    margin-top: -22.49rem;
  }
}
