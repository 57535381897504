.ProductContainer .buttons {
  display: flex;
  justify-content: space-around;
  top: 20px;
  left: 20px;
}

.ProductContainer .buttons button {
  width: 300px;
  height: 50px;
  background-color: #daf5ff;
  margin: 20px;
  color: #568fa6;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.ProductContainer .buttons button:before,
.ProductContainer .buttons button:after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #0099cc;
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.ProductContainer .buttons button:before {
  right: 0;
  top: 0;
  transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.ProductContainer .buttons button:after {
  left: 0;
  bottom: 0;
}

.ProductContainer .buttons button span {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.ProductContainer .buttons button span:before,
.ProductContainer .buttons button span:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 0;
  background-color: #0099cc;
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.ProductContainer .buttons button span:before {
  right: 0;
  top: 0;
  transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.ProductContainer .buttons button span:after {
  left: 0;
  bottom: 0;
}

.ProductContainer .buttons button p {
  padding: 0;
  margin: 0;
  transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
  position: absolute;
  width: 100%;
  height: 100%;
}

.ProductContainer .buttons button p:before,
.ProductContainer .buttons button p:after {
  position: absolute;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
  z-index: 1;
  left: 0;
}

.ProductContainer .buttons button p:before {
  content: attr(data-title);
  top: 50%;
  transform: translateY(-50%);
}

.ProductContainer .buttons button p:after {
  content: attr(data-text);
  top: 150%;
  color: #0099cc;
}

.ProductContainer:hover .buttons button:before,
.ProductContainer:hover .buttons button:after {
  width: 100%;
}

.ProductContainer:hover .buttons button span {
  z-index: 1;
}

.ProductContainer:hover .buttons button span:before,
.ProductContainer:hover .buttons button span:after {
  height: 100%;
}

.ProductContainer:hover .buttons button p:before {
  top: -100%;
  transform: rotate(10deg);
}

.ProductContainer:hover .buttons button p:after {
  top: 50%;
  transform: translateY(-50%);
}

.ProductContainer .buttons button.start {
  background-color: #0099cc;
  box-shadow: 0px 5px 10px -10px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.ProductContainer .buttons button.start p:before {
  top: -50%;
  transform: rotate(5deg);
}

.ProductContainer .buttons button.start p:after {
  color: white;
  transition: all 0s ease;
  content: attr(data-start);
  top: 50%;
  transform: translateY(-50%);
  animation: start 0.3s ease;
  animation-fill-mode: forwards;
}

@keyframes start {
  from {
    top: -50%;
  }
}

.ProductContainer:hover .buttons button.start:before,
.ProductContainer:hover .buttons button.start:after {
  display: none;
}

.ProductContainer:hover .buttons button.start span {
  display: none;
}

.ProductContainer .buttons button:active {
  outline: none;
  border: none;
}

.ProductContainer .buttons button:focus {
  outline: 0;
}
