.button {
  padding: 1.3em 3em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 600;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.button:hover {
  background-color: #0099cc;
  box-shadow: 0px 15px 20px rgba(90, 249, 255, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

.button:active {
  transform: translateY(-1px);
}
