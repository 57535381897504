.my-swiper-partner .swiperPn {
  margin-top: 64px;
  width: 1280px;
  height: 50px;
}

.my-swiper-partner .swiperPn .swiperPn-slide {
  width: 30px;
  height: 50px;
  z-index: 2;
  text-align: center;
  font-size: 16px;
  background-color: transparent;
  box-shadow: none;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-swiper-partner .swiperPn .swiperPn-slide img {
  width: 100%;
  height: 50px;
  display: block;
  object-fit: contain;
  filter: grayscale(100%);
  transition: 0.3s;
}

.my-swiper-partner .swiper .swiper-slide:hover img {
  filter: grayscale(0);
}
