.swiper {
  width: 1280px;
  position: relative;
  padding-bottom: 20px;
}

.swiper-slide {
  z-index: 2;
  height: 300px;
  text-align: center;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.swiper-slide img {
  height: 300px;
  width: 100%;
  display: block;
  object-fit: cover;
}

/* .swiper-slide::before {
  color: black;
  position: absolute;
  content: attr(data-content);
  bottom: 0;
  left: 0;
  width: 0;
  height: 50px;
  background-color: #09c;
  z-index: 9999;
  transition: 0.5s;
} */

.swiper-slide:hover::before {
  width: 100%;
}
